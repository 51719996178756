import React, { useEffect ,useState} from 'react'
import axios from 'axios'
import { Container, Typography ,Grid,CircularProgress,TextField,CardActionArea,CardActions} from '@mui/material'
import { Link, useParams } from 'react-router-dom'
import { AiOutlineHeart, AiOutlineShoppingCart, AiFillCloseCircle,AiOutlineClose } from 'react-icons/ai'
import { Box } from '@mui/system'
import { useContext } from 'react'
import { ContextFunction } from '../../Context/Context'
import CategoryCard from '../../Components/Category_Card/CategoryCard';
import BannerData from '../../Helpers/HomePageBanner';
import Carousel from '../../Components/Carousel/Carousel'
import SearchBar from '../../Components/SearchBar/SearchBar'
import CopyRight from '../../Components/CopyRight/CopyRight'
import Slider from "react-slick";
import ProductCard from '../../Components/Card/Product Card/ProductCard'
import  UpdateProduct from "../../function/UpdateProduct";
import { toast } from 'react-toastify';
import TimeAgo from 'react-timeago'
import './homepage.css'
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";
const HomePage = () => {
    const { setCart } = useContext(ContextFunction)
    const [productData, setProductData] = useState([])
    const [loading,setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [productOld, setProductOld] = useState([])
    const [collections,setCollections] = useState([])
    const [sections,setSections]= useState([])
    const [closeSearch,setCloseSearch]= useState(false);
    const [show, setShow] = useState(false)

    let authToken = localStorage.getItem('Authorization')
    useEffect(() => {
      // window.addEventListener('scroll', controlNavbar)
      // return () => {
      //     window.removeEventListener('scroll', controlNavbar)
      // }
      setLoading(true)
      getAllProduct()
      getPublicCollection()
      getPublicSelection()
        getCart()
        window.scroll(0, 0)
    }, [])

    useEffect(() => {
      window.addEventListener('scroll', controlNavbar)
      return () => {
          window.removeEventListener('scroll', controlNavbar)
      }
  }, [])

    const getAllProduct = async () => {
      // console.log('process.env.REACT_APP_GET_CART',process.env.REACT_APP_GET_CART)
      if (authToken !== null) {
          const { data } = await axios.get(`${process.env.REACT_APP_FETCH_PRODUCT}`,

              {
                  headers: {
                      'Authorization': authToken
                  }
              })
        // console.log('-----------------4---------data',data)
        setLoading(false)
        setProductData(data);
        setProductOld(data)
       
      }else{
        const { data } = await axios.get(`${process.env.REACT_APP_FETCH_PRODUCT}`,

              {
                  headers: {
                      'Authorization': authToken
                  }
              })
        // console.log('---------------5-------data',data)
        setLoading(false)
        setProductData(data);
        setProductOld(data)
        // setProductOld(data)
        
      }

  }
  const controlNavbar = () => {
        if (window.scrollY > 250 ) {
            setShow(true)
        }else{
          setShow(false)
        }
    }
    const getPublicCollection = async ()=>{
      const { data } = await axios.get(`${process.env.REACT_APP_COLLECTION}/public`,
              {
                  headers: {
                      'Authorization': authToken
                  }
              })
        // console.log('--------REACT_APP_COLLECTION------5-------data',data.data)
        // setLoading(false)
        setCollections(data.data);
        // setProductOld(data)
    }


    const getPublicSelection = async ()=>{
      const { data } = await axios.get(`${process.env.REACT_APP_SECTIONS}/public`,
              {
                  headers: {
                      'Authorization': authToken
                  }
              })
        // console.log('--------REACT_APP_COLLECTION------5-------data',data.data)
        // setLoading(false)
        setSections(data.data);
        // setProductOld(data)
    }



    

    const getCart = async () => {
        // console.log('process.env.REACT_APP_GET_CART',process.env.REACT_APP_GET_CART)
        if (authToken !== null) {
            const { data } = await axios.get(`${process.env.REACT_APP_GET_CART}`,

                {
                    headers: {
                        'Authorization': authToken
                    }
                })
                setLoading(false)
          // console.log('-------------data',data)
            setCart(data);
        }

    }

    const  SampleNextArrow =(props) =>{
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style, display: "none", background: "red" }}
          onClick={onClick}
        />
      );
    }

    const  SamplePrevArrow =(props)=> {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style, display: "none", background: "green" }}
          onClick={onClick}
        />
      );
    }

    const handleSearch = async (event) => {
      // console.log("data ----false--article----3->",event.target.value)
      try {
        setLoading(true)
      setSearchTerm(event.target.value);
      setCloseSearch(true)
         if(event.target.value.length >= 2){
          
                const { data } = await axios.get(`${process.env.REACT_APP_PRODUCT}/index?search==${event.target.value}`,
               {
                    headers: {
                        'Authorization': authToken
                    }
                })
    
                // console.log('--------------res---4---------',data.data            )
                if (data.error === false) {
                  if(data.data === undefined){
    
                  }else{
                    setLoading(false)
                    setProductData(data.data)
                  }
                 
                  // toast.success("added", { autoClose: 500, theme: 'colored' })
                  
                }
    
              }
    
              } catch (error) {
                toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
            }
    };


    const handleClickShowPassword = () =>{
      // console.log("data ----false--article----3->",)
      setCloseSearch(!closeSearch)
      setProductData(productOld)
      setSearchTerm('')
    }

    function ToSeoUrl(url) {
        
      // make the url lowercase         
      var encodedUrl = url.toString().toLowerCase(); 
    
      // replace & with and           
      encodedUrl = encodedUrl.split(/\&+/).join("-and-")
    
      // remove invalid characters 
      encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");       
    
      // remove duplicates 
      encodedUrl = encodedUrl.split(/-+/).join("-");
    
      // trim leading & trailing characters 
      encodedUrl = encodedUrl.trim('-'); 
    
      return encodedUrl; 
    }
    

    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        // fade: true,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
        
      };

    return (
        <>  
            <div className='HomePage-search-nav'>
                 <div className="HomePagw-searchBody">
                        <TextField
                            id="search"
                          
                            label="Search Products"
                            size="small"
                            value={searchTerm}
                            onChange={handleSearch}
                            className={'HomePage-search-input'}
                            
                    />
                        {closeSearch ?   <a onClick={handleClickShowPassword}><AiOutlineClose    /> </a>: null}
                </div>
  
                <div className={`HomePagw-search-banner ${show && 'hidden'}`}>
                   <div className='HomePagw-search-bannerSub'>
                       <img src='https://res.cloudinary.com/codepally/image/upload/v1695911748/UFUON%20WEB%20STORE/WEB%20BANNER/adj_mini_UFUON_BANNERS_cqthhr.png'/>
                   </div>
                   <div className='HomePagw-search-bannerSub1'>
                      <img src='https://res.cloudinary.com/codepally/image/upload/v1706621483/bg_shipping_rwvm21.png' />
                      
                   </div>
                   <div className='HomePagw-search-title'>
                     <h5>- FREE DELIVERY -</h5>
                     <p>For all orders from N100,000</p>
                   </div>
                </div>
               </div>
               {closeSearch ? 
                null
                :
                  <div className='homepageSlide'  >
                    <div className='SlideImagebody'>
                      <Slider {...settings}>
                             <div className='SlideImageLeft'>
                              <img src='https://res.cloudinary.com/codepally/image/upload/v1695899778/UFUON%20WEB%20STORE/WEB%20BANNER/1_fs1ewg.png'/>
                            </div>
                            <div className='SlideImageLeft'>
                              <img src='https://res.cloudinary.com/codepally/image/upload/v1695819558/UFUON%20WEB%20STORE/WEB%20BANNER/UPDT_1_-UFUON_BANNERS_aqih5z.png'/>
                            </div>
                          
                            <div className='SlideImageLeft'>
                              <img src='https://res.cloudinary.com/codepally/image/upload/v1695899773/UFUON%20WEB%20STORE/WEB%20BANNER/3_mxuxwu.png'/>      
                            </div>
                            
                            </Slider>
                        
                    </div>
                    <div className='SlideImageRight'>
                             <div className='SlideImage'>
                              <img src='https://res.cloudinary.com/codepally/image/upload/v1693922078/UFUON%20WEB%20STORE/WEB%20BANNER/CATEGORIES/UFUON_BANNERS_05_i0p4xs.png' />
                          </div>
                            <div className='SlideImage'>
                                <img src='https://res.cloudinary.com/codepally/image/upload/v1693922083/UFUON%20WEB%20STORE/WEB%20BANNER/CATEGORIES/UFUON_BANNERS_au1bbc.png' />
                            </div>
                           
                        
                       </div>
                 </div>

               }

                 {
                  loading === true? 
                 null
                 :
                   <div>
                      {closeSearch ? 
                        null
                        :
                        <div>
                        {collections.map(prod => (  
                          <div>
                                <div className='homepage-c3768_2zwQl'>
                                    <h2 className='_069ac_1c5-v'> {prod.name}</h2> 
                                </div>
                                <div className='Homepage-collection-pro'>
                                    {prod.products.map(prod => (  
                                    
                                        <UpdateProduct to={`/Detail/type/category/${ToSeoUrl(prod.name)}/${prod._id}`} prod={prod} />

                                      ))}
                                </div>
                          </div>
                        ))}
                      </div>
                      }
                   </div>
                   
                }
                <div>
               
          </div>
          {closeSearch ? 
            null
            :
                <div className='homepage-c3768_2zwQl'>
                      <h2 className='_069ac_1c5-v'> FEATURED PRODUCTS</h2> 
                  </div>
          }
                  {
                    loading === true?
                       <div className='Homepage-loading'>
                          <p  style={{textAlign:'center'}} ><CircularProgress /></p>
                      </div>
                      :
                    <div>
                      <div  className={closeSearch ?'Homepage-product-true': 'Homepage-product'}>
                            { productData.map(prod => (
                                
                                    <UpdateProduct prod={prod} to={`/Detail/type/category/${ToSeoUrl(prod.name)}/${prod._id}`} />

                              
                            ))}
                       </div>
                    </div>
                  }
                  
                  {
                    loading === true?
                    null
                    :
                    <div className='homepage-c3768_2zwQl'>
                        <h2 className='_069ac_1c5-v home-project-all'><a href='/projects-list'> View more </a></h2> 
                        <h2 className='_069ac_1c5-v'> PROJECTS WORK</h2> 
                    </div>
                  }
                  {closeSearch ? 
                    null
                    :
                  <div>
                        {
                          loading === true?
                          null
                          :
                         <div className='Homepage-ProjectBody'>
                            {sections.map(prod => (
                                  <div className='HomePage-Collectioncard'>
                                       <Link  to={`/project/detail/${ToSeoUrl(prod.name)}/${prod._id}`}>
                                          <CardActionArea >
                                             {
                                              prod.url === undefined || prod.url === null ?
                                                <div className='home-product-img'>
                                                      <img alt={'project'} src={'https://res.cloudinary.com/codepally/image/upload/v1665659075/UFUON%20LOGO%20PNG/ufuon_logo_2_alt9px.png'} loading='lazy'  />
                                                </div>
                                               :
                                               <div className='home-product-img'>
                                                      <img alt={'project'} src={prod.url} loading='lazy'  />
                                                </div>
                                             }
                                             
                                          </CardActionArea>
                                          </Link>   
                                          <Link  to={`/project/detail/${ToSeoUrl(prod.name)}/${prod._id}`}>
                                          
                                             <h6>
                                               { prod.name.length > 35 ? prod.name.slice(0, 35) + '...' : prod.name}
                                            </h6>
                                         
                                         
                                    
                                          
                                          <div className='Homepage-product-sub'>
                                            <p>Products</p>  <div className='circle'> {prod.products.length} </div>  <p>| <TimeAgo  ago={false} date={ prod.createdAt} /> </p>
                                            
                                          </div>
                                        
                                          </Link>
                                          <CardActions style={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
                                    </CardActions>
                                    </div>
                                ))}
                          </div>
                          }
                  
                  </div>
                  }
               

                   <div className='homepageCategory'>
                    <Typography variant='h5' sx={{ textAlign: 'center', marginTop: 4, color: 'rgb(232, 62, 140)', fontWeight: 'bold' }}>Categories</Typography>
                        <Container maxWidth='xl' style={{ marginTop: 10, display: "flex", justifyContent: 'center', flexGrow: 1, flexWrap: 'wrap', gap: 40, }}>
                            {
                                BannerData.map(data => (
                                    <CategoryCard data={data} key={data.img} />
                                ))
                            }
                        </Container>
                    </div>
             
            <CopyRight sx={{ mt: 8, mb: 10 }} />
        </ >
    )
}

export default HomePage