import React, { useEffect ,useState,useCallback} from 'react'
import axios from 'axios'
import {
   Button, Dialog, DialogActions, DialogContent, DialogTitle,Skeleton,  Rating, Divider,Tooltip, Grid, TextField, Typography, InputLabel, MenuItem, FormControl, Select, CircularProgress
} from '@mui/material';
import { Link, useParams,useNavigate } from 'react-router-dom'
import { Box } from '@mui/system'
import { useContext } from 'react'
import { ContextFunction } from '../../Context/Context'
import { getCart, getWishList, handleLogOut, handleClickOpen, Transition } from '../../Constants/Constant'
import { AiFillHeart, AiFillCloseCircle, AiOutlineLogin, AiOutlineShareAlt ,AiFillDelete} from 'react-icons/ai'
import { MdAddShoppingCart,MdOutlineAdd ,MdOutlineCancel,MdProductionQuantityLimits,MdEditDocument} from 'react-icons/md'
import Slider from "react-slick";
import { useDropzone } from "react-dropzone"
import { BsImage } from 'react-icons/bs'
import {BiDownArrow ,BiSolidDownArrow} from 'react-icons/bi'
import {CiRead,CiUnread} from 'react-icons/ci'
// import MarkdownInput from "@opuscapita/react-markdown"
import MarkdownEditor from "@uiw/react-markdown-editor";
import MarkdownPreview from '@uiw/react-markdown-preview';
import { toast } from 'react-toastify';
import ImageGallery from 'react-image-gallery';
// import ProductCard from './ProductCollectionCard'
import './collectionDetail.css'
import {
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton,
  LinkedinIcon,
  PinterestShareButton,
  PinterestIcon,
  EmailShareButton,
  EmailIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon
  
} from 'react-share';
const  CollectionDetail = (props) => {
  
  const code = `# title\n\nHello World!\n\n`;
    const { setCart } = useContext(ContextFunction)
    const { id, cat } = useParams()
    const [productData, setProductData] = useState([])
    const [loading,setLoading] = useState(false);
    // const [section,setSection] = useState(null);
    const [section , setSection] = useState(null)
    const [openAlert, setOpenAlert] = useState(false);
    const [sectionData,setSectionData]= useState(null)
    const [images, setImages]= useState([])
    const [showData,setShowData]=useState(null)
    const [totalProduct,setTotalProduct] = useState(0)
    const [products,setProducts] = useState([])
    // const navigate = useNavigate()
    const [getData,setGetData] = useState(null);
    const [openEdit, setOpenEdit] = useState(false);
    const [loadingData,setLoadingData] = useState(false);
    const [shareMoald,setShareMoald] = useState(false);
    const [quantitys,setQuantitys]= useState([]);
    const [step,setStep]= useState({page:1,title:'collection'})
    const [loadPublish,setLoadPublish]= useState(false);
    const [publish,setPublish]= useState(false);
    const [markdownVal, setMarkdownVal] = useState(code);
    const shareUrl = 'https://www.store.ufuon.com/project/detail';
    // console.log("markdownVal:", markdownVal);
    const [login,setLogin] = useState(false);
    const [openImage,setOpenImage]= useState(false);
    const [showImageUi,setShowImageUi] = useState(false)
    const [source,setSource] = useState('')
    const [showProductData,setShowProductData] = useState(false)
    const [loadingImage, setLoadingImage]= useState(false)
    let navigate = useNavigate()
    const [myFiles, setMyFiles] = useState([])
    const [allFile,setAllFile]= useState([]);
    const [type,setType]= useState(null);
    const [typeName, setTypeName]= useState(null);
    const [projects , setProjects] = useState([])
    const [productInfo, setProductInfo] = useState({
      name: "",
      title:"",
      Description:"",
      description:''});
    let authToken = localStorage.getItem('Authorization')
    let setProceed = authToken ? true : false
    useEffect(() => {
      setLoading(true)
      getAllSection()
 
        window.scroll(0, 0)
    }, [])

    const getAllSection = async () => {
      const ArrayDATA = []
      // console.log('process.env.REACT_APP_GET_CART',process.env.REACT_APP_GET_CART)
      if (setProceed) {
             var { data } = await axios.get(`${process.env.REACT_APP_SECTIONS}/${id}`,
              {
                  headers: {
                      'Authorization': authToken
                  }
              })
              setSectionData(data.data)
              if( data.data.products.length === 0){
                // console.log('checking data---true---------->', data.data.products.length )
                setLoading(false)
                setSection(null)
              }else{
                setSection(data.data.products[0])
                if(data.data.publish === undefined ){
                    setPublish(false)
                }else{
                   if(data.data.publish === false ){
                    setPublish(false)
                   }else{
                    setPublish(true)
                   }
                }
                if(data.data.category === null || data.data.category === undefined){
                  setTypeName("Pick category")
                }else{
                  setTypeName(data.data.category.name)
                  setType(data.data.category._id)
                }
                
                setProducts(formattedData(data.data.products, data.data.quantity))
                setQuantitys(data.data.quantity)
                CountTotal(formattedData(data.data.products, data.data.quantity))
                setShowData(data.data.products[0])
                var images = data.data.products[0]
                      for (let i = 0; i < images.images.length; i++) {
                        // console.log('data----------images------->',images.images[i].url)
                            const newData ={
                                    original:  images.images[i].url,
                                    thumbnail:  images.images[i].url,
                            }
                            //  setImages([...images, newData])
                      ArrayDATA.push(newData)
                }
                setImages(ArrayDATA)
                setLoading(false)
              }
              var { data } = await axios.get(`${process.env.REACT_APP_CATEGORY}`,
              {
                  headers: {
                      'Authorization': authToken
                  }
              })
              setProjects(data.data)

        ;
     
        // console.log('checking data---true---------->', data.data)
        // setProducts(data.data.products)
        

       
        
      }else{
        setLogin(true)
      }

    }

    const onDrop = useCallback(acceptedFiles => {
      setMyFiles([...myFiles, ...acceptedFiles])
    })
  
    const { getRootProps, getInputProps   , acceptedFiles,  fileRejections, } = useDropzone({
      onDrop,
      accept: {
        'image/jpeg': [],
        'image/png': [],
        'image/jpg': [],
      },
      maxFiles:1,
      maxSize:2000000,
    //   onDropRejected,
    })

    const formattedData = (data, array) => data.map((obj, i) => (  
      {
        ...obj,
        quantity: array[i]
      }
    ));

  const handleClose = () => {
    setOpenAlert(false);
    setShareMoald(false)
    // setOpenEdit(false)
};
 


   
   const clickModal =()=>{
    setOpenAlert(true)
   }

     // const [productInfo, setCredentials] = useState({ firstName: "", lastName: '', email: "", phoneNumber: '', password: "" })
  const handleOnchange = (e) => {
    
    setProductInfo({ ...productInfo, [e.target.name]: e.target.value })
}


   const SendDataSelect = (data) =>{
    props. pickSelection(data)
    // props.SendDataToCallection(data)
   }
   
   const SendDataToCallection = (data) =>{

   }

   const handleToLogin = () => {
    navigate('/login')
};
    


   const ClickToShow =(data)=>{
    setShowData(data)
    const ArrayDATA = []
    var images =  data.images
    for (let i = 0; i < data.images.length; i++) {
      // console.log('data----------images------->', data.images[i].url)
                const newData ={
                        original:  data.images[i].url,
                        thumbnail:  data.images[i].url,
                }
                //  setImages([...images, newData])
          ArrayDATA.push(newData)
      }
      setImages(ArrayDATA)
      setShowProductData(true)
   }


   const CountTotal = (data)=> {
    // const  quantity = 1
      var totaltAll = data.reduce(function(res,item) {
        return res + (item.price * item.quantity);
      }, 0);
      setTotalProduct(totaltAll)
      console.log(totaltAll);

  }

  const getProduct = (data) =>{
    // console.log('data product--------->',data)
    navigate('/list')
  }

  const shareProduct = (data) =>{ 
    // console.log('--------data--------->',data)
    setShareMoald(true)
  }

  const removeProductData = async(data , index) =>{

    setLoadingData(true)
    var removeBook = products.filter(item => item._id !== data._id)
    quantitys.splice(index, 1)
    CountTotal(formattedData(removeBook, quantitys))
    setProducts(removeBook)
    // CountTotal(removeBook)
  
    const ArrayDATA = []
    // var images =  data.images
    var product = removeBook[0]
      if(removeBook.length === 0){
        setSection(null)
         }else{
        for (let i = 0; i < product.images.length; i++) {
          // console.log('data----------images------->', product.images[i].url)
                    const newData ={
                            original:  product.images[i].url,
                            thumbnail:  product.images[i].url,
                    }
                    //  setImages([...images, newData])
              ArrayDATA.push(newData)
            }
        }
   
      if(removeBook.length === 0){
        setImages([])
          }else{
          setImages(ArrayDATA)
      }
      
        try {
         const { data } = await axios.put(`${process.env.REACT_APP_SECTIONS}/${sectionData._id}`,
                  {
                    products: removeBook,
                    quantity:quantitys
                  }, {
                  headers: {
                      'Authorization': authToken
                  }
              })
              setOpenAlert(false);
              if (data.status === true) {
                toast.success("Collection Updated", { autoClose: 500, theme: 'colored' })
                  // setProductInfo({
                  //     name: "",
                      
                  //    });
                  setSectionData(data.data)
                 setLoadingData(false)
                 setOpenEdit(false)
              }
              else {
                setLoadingData(false)
                  toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
              }
         } catch (error) {
           setLoadingData(false)
          toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
      }
  }


   const  moneyFormat =(price, sign = 'N') =>{
    const pieces = parseFloat(price).toFixed(2).split('')
    let ii = pieces.length - 3
    while ((ii-=3) > 0) {
      pieces.splice(ii, 0, ',')
    }
    return sign + pieces.join('')
  }

  

 const EditCollection = () =>{
    setOpenAlert(true)
    productInfo.name = sectionData.name
    productInfo.Description = sectionData.title
    productInfo.description =  sectionData.description
    setMarkdownVal(sectionData.description)
    // productInfo.description 
 }
  

 

  const UpdateSubmit = async (e) =>{
    e.preventDefault()
    setLoadingData(true)
    // console.log('-----------------4---------data',productInfo.name,)
    try {
        if (!productInfo.name ) {
            toast.error("Please Fill the all Fields", { autoClose: 500, theme: 'colored' })
            setLoadingData(false)
          }
        else {
            const { data } = await axios.put(`${process.env.REACT_APP_SECTIONS}/${sectionData._id}`,
                {
                    name: productInfo.name,
                    company :'store',
                    description:markdownVal,
                    category:type,
                    title:productInfo.Description
                }, {
                headers: {
                    'Authorization': authToken
                }
            })
            setOpenAlert(false);
            // console.log('-----------------4---------data',data)
            if (data.status === true) {
              toast.success("Collection Updated", { autoClose: 500, theme: 'colored' })
              // console.log('-----------------4---------data',data.data)
              setSectionData(data.data)
               setLoadingData(false)
               setOpenEdit(false)
            }
            else {
              setLoadingData(false)
                toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
            }
        }
       } catch (error) {
         setLoadingData(false)
        toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
    }
  }

  const PublishData = () =>{

  }


  const dataForSwitch = async()=>{
         
        setLoadPublish(true)

        try {
        
              const { data } = await axios.post(`${process.env.REACT_APP_SECTIONS}/publish`,
                  {
                    id:sectionData._id,
                      publish:!publish
                  }, {
                  headers: {
                      'Authorization': authToken
                  }
              })
              // setOpenAlert(false);
              // console.log('-----------------4---------data',data)
              if (data.status === true) {
                toast.success("Collection Updated", { autoClose: 500, theme: 'colored' })
                setLoadPublish(false)
                setPublish(!publish )
                setLoadPublish(false)
              }else {
                setLoadPublish(false)
                  toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
              }
        
          } catch (error) {
          setLoadPublish(false)
          toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
      }
    
  }

  const GetProduct = () =>{
    setStep({page:2,title:'Address'})
  }


 const  handleValueChange=(e)=>{
  setSource(e)
  productInfo.description = e
    // console.log("evt.target.value--------->",e)
}

function ToSeoUrl(url) {
        
  // make the url lowercase         
  var encodedUrl = url.toString().toLowerCase(); 

  // replace & with and           
  encodedUrl = encodedUrl.split(/\&+/).join("-and-")

  // remove invalid characters 
  encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");       

  // remove duplicates 
  encodedUrl = encodedUrl.split(/-+/).join("-");

  // trim leading & trailing characters 
  encodedUrl = encodedUrl.trim('-'); 

  return encodedUrl; 
}

const openImageData = () =>{
  setOpenImage(true)
  setShowImageUi(true)
}
const removeFile = file => () => {
  const newFiles = [...myFiles]
  newFiles.splice(newFiles.indexOf(file), 1)
  setMyFiles(newFiles)
}


const files = myFiles.map(file => (
  <li key={file.path} style={{ marginBottom:'2%'}}>
    {file.path} - {file.size} bytes{" "}
    <a style={{ float:"right",}} onClick={removeFile(file)}>
          <Tooltip
          title='Remove From Cart'>
          <Button className='all-btn' sx={{ width: 7, borderRadius: '30px' }} variant='contained' color='error' >
              <AiFillDelete style={{ fontSize: 15 }} />
          </Button>
      </Tooltip>
    </a>
  </li>
))


const uploadFileAws=() => {
  // console.log('data-------------->',myFiles)

  var  arrayData = null
    setLoadingImage(true)
  //   console.log('data-------------->',bucket)
    const uploaders = myFiles.map(image => {
      const formData = new FormData();
      console.log('done image---->',  image);
      formData.append('file', image);
      formData.append("upload_preset", "tutorial")
      formData.append("cloud_name","breellz")
      const options = {
          method: 'POST',
          headers: { 
              'content-type': 'multipart/form-data',
          'access-control-allow-origin': '*',
        //   'Authorization': Auth.getToken(), 
          },
            url:'https://api.cloudinary.com/v1_1/dghjdfdfz/image/upload',
        };
    // Make an AJAX upload request using Axio
      return  axios.post('https://api.cloudinary.com/v1_1/dghjdfdfz/image/upload', formData, options.headers)
          .then(response => {
              console.log("response", response.data)
           
              const data = {
                  data:response.data.asset_id,
                  version_id:response.data.asset_id,
                  public:response.data.public_id,
                  url:response.data.url,
                  versionNum:response.data.version
              }
               arrayData =data 
              // setAllFile([...allFile, data])
              console.log('save data---------->', data)
                if(response.data.status ===  "success"){
                    
                  }else{
                      
                    toast.success('Uploadeding');
                  }
            
                })
              
            });
    
      // Once all the files are uploaded 
        axios.all(uploaders).then(() => {
         setAllFile(arrayData)
       sendImageUpdata(arrayData)
      
     }).catch(err =>   toast.error(err));
  }

  const sendImageUpdata = async (dataFile)=>{
    console.log('sending file to server----------->',dataFile)
       try {
            const { data } = await axios.put(`${process.env.REACT_APP_SECTIONS}/${sectionData._id}`,
                    {
                        imageFile: dataFile,
                        url :dataFile.url

                    }, {
                    headers: {
                        'Authorization': authToken
                    }
                })
                // setOpenAlert(false);
                // console.log('-----------------4---------data',data)
                if (data.status === true) {
                  toast.success("Collection Updated", { autoClose: 500, theme: 'colored' })
                  toast.success('Uploadeding');
                  setOpenImage(false)
                  setShowImageUi(false)
                  setLoadingImage(false)
                  setOpenAlert(false)  
                  setSectionData(data.data);  
                }
                else {
                  setLoadingData(false)
                  setLoadingImage(false)
                    toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
                }
          } catch (error) {
            setLoadingData(false)
            setLoadingImage(false)
            toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
      }
  }


  const  handleType= (e) => {
    // console.log("----=--------->",e.target.value )     
    setType(e.target.value) 
  //  props.getAge(e.target.value)
 }

  const productFilter = ["name","user"]
    return (
     
           <>
            {
              step.page === 1?
                    <div>
                    {
                      loading === true?
                        <div style={{paddingTop:'7%',alignItems: 'center',justifyContent: 'center',width:'25%',margin:'auto'}}>
                        <p  style={{textAlign:'center'}} ><CircularProgress /></p>
                        </div>
                        :
                        <div className='collection-detail'>
                        {
                          sectionData === null ||  sectionData === undefined?
                          null
                          :
                           <div className='collectionDetail-moblie'>
                              <div className='collectionDetail-price'>
                         
                                  <div className='collectionDetail-listName'>
                                    <h3>{sectionData.name} </h3>
                                    <h4> {moneyFormat(totalProduct)} </h4>
                                      
                                  </div>
                                
                                    <Button variant='contained' className='all-btn ' style={{ marginRight:'1%' }}  startIcon={  <MdEditDocument style={{ fontSize: 25 }}/>} onClick={()=>EditCollection()}>Edit</Button>
                                    {
                                      loadPublish === true ?
                                      <Button loading variant="outlined">
                                          Loading
                                        </Button>
                                      :
                                      <>
                                          {
                                            publish ?
                                              <Button variant='contained' className='all-btn '   color="success" startIcon={  <CiRead style={{ fontSize: 25  }}/>} onClick={(() => dataForSwitch())}> Publish </Button>
                                              :
                                              <Button variant='contained' className='all-btn '   startIcon={  <CiUnread style={{ fontSize: 25 }}/>} onClick={(() => dataForSwitch())}>  Unpublish</Button>
                                            }  
                                      </> 
                                    }
                                  
                                  {
                                     publish === true ?
                                      <Button style={{ marginLeft: 10 }} variant='contained' className='all-btn' startIcon={<AiOutlineShareAlt />} onClick={() => shareProduct(sectionData)}>Share</Button>
                                    :
                                    <Button variant='contained' className='all-btn ' style={{ width:'15%' ,marginLeft: 10}}  startIcon={  <MdOutlineAdd style={{ fontSize: 25 }}/>} onClick={(() => getProduct())}>Product</Button>
                                  }
                                </div>
                                 
                              </div>
                            }
                          
                            <div className='collection-detail-show'>
                            {
                              sectionData == null?
                              null
                              :
                              <div >
                              {
                                showProductData  === true ?
                                      <div>
                                         <a href='#' style={{float:'right',position:'relative',top:'3%'}} onClick={()=>setShowProductData(false)}>
                                         <AiFillCloseCircle style={{ fontSize: 25 }}/>  
                                         </a> 
                                          {loading ? (
                                                    <Skeleton variant='rectangular' height={400} />
                                                ) : (
                                                    <div className="product-image">
                                                    {
                                                      images.length  === 0 ?
                                                      <h3 className='productDetail-title'>{sectionData.name} </h3>
                                                      :
                                                      <ImageGallery items={images} />
                                                    }
                                                      
                                                    </div>
                                                )} 
                                        </div>
                                    :
                                    <div className='collectionDetail-image-url'>
                                      {
                                        sectionData.url ?
                                        <img src={sectionData.url} />
                                        :
                                        <div style={{ alignItems: 'center',justifyContent: 'center',textAlign:'center',paddingTop:'5%'}}>
                                              <BsImage  style={{ fontSize: 200 }}/> 
                                                <br/>
                                              <h1> No Image Yet</h1>
                                        </div>
                                      }
                                          
                                    </div>  
                                }
                                    
                                
                              </div>
                              }
                              {
                                showProductData  === true ?
                              <div>
                                    {
                                      section === null?
                                      null
                                      :
                                      <div style={{width:'95%'}}>
                                      {
                                        showData === null || showData === undefined ?
                                        null 
                                        :
                                        <div>
                                            <h3>{showData.name}</h3> 
                                            
                                            <h3>{moneyFormat(showData.price)}</h3>                      
                                            <p><a href="#"> Q{showData.quantity}</a></p>
                                            <Rating name="read-only" value={Math.round(showData.rating)} size='small'  readOnly precision={0.5} /> 
                                            <br/>
                                            <h7>{showData.description}</h7> 
                                          
                                        </div>
                                      }
                                        
                                    </div>
                                    }
                              </div>
                              :
                                 null
                              }
                             
                              
                            </div>
                            {
                              sectionData == null?
                              null
                              :
                              <div className='collectionDetail-list'>
                                <div className='collectionDetail-web'>
                                    <div className='collectionDetail-price'>
                                    
                                      <h2> {moneyFormat(totalProduct)} </h2>
                                        <Button variant='contained' className='all-btn ' style={{ marginRight:'1%' }}  startIcon={  <MdEditDocument style={{ fontSize: 25 }}/>} onClick={()=>EditCollection()}>Edit</Button>
                                        {
                                          loadPublish === true ?
                                          <Button loading variant="outlined">
                                              Loading
                                            </Button>
                                          :
                                          <>
                                              {
                                                publish ?
                                                  <Button variant='contained' className='all-btn ' style={{ width:'15%' }}  color="success" startIcon={  <CiRead style={{ fontSize: 25 }}/>} onClick={(() => dataForSwitch())}> Publish </Button>
                                                  :
                                                  <Button variant='contained' className='all-btn ' style={{ width:'15%' }}  startIcon={  <CiUnread style={{ fontSize: 25 }}/>} onClick={(() => dataForSwitch())}>  Unpublish</Button>
                                                }  
                                          </> 
                                        }
                                      
                                    {
                                          publish === true ?
                                          <Button style={{ marginLeft: 10 }} variant='contained' className='all-btn' startIcon={<AiOutlineShareAlt />} onClick={() => shareProduct(sectionData)}>Share</Button>
                                        :
                                        <Button variant='contained' className='all-btn ' style={{ width:'15%' ,marginLeft: 10}}  startIcon={  <MdOutlineAdd style={{ fontSize: 25 }}/>} onClick={(() => getProduct())}>Product</Button>
                                      }
                                    </div>
                                      <div className='collectionDetail-listName'>
                                      {
                                        sectionData.products.length === 0 ?
                                       null
                                        :
                                        <h3 className='productDetail-title'>{sectionData.name} </h3>
                                      }
                                      
                                          
                                      </div>
                                      
                                  </div>
                                    
                                    {
                                      loadingData === true?
                                          <div style={{marginTop:'10%',alignItems: 'center',justifyContent: 'center',width:'10%',margin:'auto'}}>
                                                <p  style={{textAlign:'center'}} >  <CircularProgress  /> </p>
                                          </div>
                                        :
                                        <div className='collectionDetail-list-sub'>
                                        {
                                          products.map((data , index)=> (
                                            <div className='collectionDetail-product'>
                                                <a href='#' onClick={()=>ClickToShow(data)}>
                                                  <div className='collectionDetail-product-img'>
                                                        <img src={data.images[0].url}/> 
                                                    </div>
                                                  </a>  
                                                  <p className='collectionDetail-quantity'><a href="#"> Q{data.quantity}</a></p>
                                                    <h6>  {data.name.length > 35 ? data.name.slice(0, 35) + '...' : data.name}</h6>
                                                 
                                                 
                                                    <h6>  {moneyFormat(data.price)}</h6>
                                                  
                                                    <p className='collectionDetail-delete'><a href="#" onClick={()=>removeProductData(data,index)}><AiFillDelete style={{ fontSize: 23 }} color='red'/> </a></p>
                                                    <Rating name="read-only" value={Math.round(data.rating)} size='small'  readOnly precision={0.5} /> 
                                            </div>
                                            ))
                                          }
                                        </div>
                                      }
                                    {
                                        sectionData === null ?
                                      null
                                      :
                                       <div style={{width:'98%',margin:'auto',}}>
                                          <MarkdownPreview source={sectionData.description} />
                                       </div>
                                        
                                    }
                                    <br/>
                                    {
                                      publish ===true?
                                         null
                                        :
                                        <div className="" style={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center',marginTop:'5%'}}>
                                            <Button variant='contained' className='all-btn ' style={{ width:'15%' }}  startIcon={  <MdOutlineAdd style={{ fontSize: 25 }}/>} onClick={(() => getProduct())}>Product</Button>
                                        </div>
                                    }
                                 
                                         
                                      </div>
                                    }
                                  
                                </div>
                            }
          
                                <Dialog
                                    open={shareMoald}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={handleClose}
                                    aria-describedby="alert-dialog-slide-description"  >
                                  
                                    <DialogContent sx={{ width: { xs: 180, md: 550, xl: 200 },  justifyContent: 'center' }}>
                                    <h3>Share Projects</h3>
                                    <br/>
                                          <div className='collectDetail-share'>
                                    {
                                      sectionData === null ?
                                      null
                                     :        
                                     <ul>
                                          <li>
                                              <FacebookShareButton
                                                url={shareUrl+'/'+ToSeoUrl(sectionData.name)+'/'+sectionData._id}
                                                quote={sectionData.name  +' ' + sectionData.description }
                                                hashtag={sectionData.name }  >
                                                <FacebookIcon size={40} round={true} />
                                              </FacebookShareButton>
                                          </li>
                                          <li>
                                              <WhatsappShareButton
                                              url={shareUrl+'/'+ToSeoUrl(sectionData.name)+'/'+sectionData._id}
                                              quote={sectionData.name  +' ' + sectionData.description }
                                              hashtag={sectionData.name } >
                                                <WhatsappIcon size={40} round={true} />
                                            </WhatsappShareButton>
                                          </li>
                                          <li>
                                              <TwitterShareButton
                                              url={shareUrl+'/'+ToSeoUrl(sectionData.name)+'/'+sectionData._id}
                                                quote={sectionData.name  +' ' + sectionData.description }
                                                title={sectionData.name  +' ' + sectionData.description }
                                                via={sectionData.name }
                                                hashtag={sectionData.name } >
                                                <TwitterIcon size={40} round={true} />
                                            </TwitterShareButton>
                                          </li>
                                          <li>
                                            <TelegramShareButton
                                            url={shareUrl+'/'+ToSeoUrl(sectionData.name)+'/'+sectionData._id}
                                                quote={sectionData.name  +' ' + sectionData.description }
                                                hashtag={sectionData.name }>
                                            <TelegramIcon size={40} round={true} />
                                            </TelegramShareButton>
                                          </li>
                                          <li>
                                            <LinkedinShareButton
                                            url={shareUrl+'/'+ToSeoUrl(sectionData.name)+'/'+sectionData._id}
                                                quote={sectionData.name  +' ' + sectionData.description }
                                                summary={sectionData.name  +' ' + sectionData.description }
                                                hashtag={sectionData.name }>
                                            <LinkedinIcon size={40} round={true} />
                                            </LinkedinShareButton>
                                          </li>
  
                                              <li>
                                                <PinterestShareButton
                                                url={shareUrl+'/'+ToSeoUrl(sectionData.name)+'/'+sectionData._id}
                                                    quote={sectionData.name  +' ' + sectionData.description }
                                                    hashtag={sectionData.name }>
                                                <PinterestIcon size={40} round={true} />
                                                </PinterestShareButton>
                                              </li>
                                              <li>
                                                <EmailShareButton
                                                url={shareUrl+'/'+ToSeoUrl(sectionData.name)+'/'+sectionData._id}
                                                    quote={sectionData.name  +' ' + sectionData.description }
                                                    hashtag={sectionData.name }>
                                                <EmailIcon size={40} round={true} />
                                                </EmailShareButton>
                                              </li>
                                              <li>
                                                <FacebookMessengerShareButton
                                                url={shareUrl+'/'+ToSeoUrl(sectionData.name)+'/'+sectionData._id}
                                                    quote={sectionData.name  +' ' + sectionData.description }
                                                    hashtag={sectionData.name }>
                                                <FacebookMessengerIcon size={40} round={true} />
                                                </FacebookMessengerShareButton>
                                              </li>
                                          </ul>
                                       }
                                   </div>
                                 </DialogContent>
                            </Dialog>
                          
                            <Dialog
                            open={openAlert}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleClose}
                            aria-describedby="alert-dialog-slide-description"
                          >
                          {
                            sectionData === null ?
                            null
                            :
                            <form onSubmit={UpdateSubmit}>
                            {
                              showImageUi === true ? 
                              <DialogContent sx={{ width: { xs: 180, md: 550, xl: 200 },  justifyContent: 'center' }}>
                              <div>
                                  <small style={{ float:'right',color:'red'}}>Length of image  {allFile.length}</small>
                                <p style={{marginBottom:'5px'}}><b>  Add Image or drag and drop image </b></p>  
                              </div>
                              
                                     
                                    <section className="containerDropzone">
                                    <div {...getRootProps({className: 'dropzone disabled'})}>
                                        <input {...getInputProps()} />
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                    </div>
                                    <aside>
                                        <h4>Files</h4>
                                        <ul>{files}</ul>
                                    </aside>
                                  
                                       
                                      
                                      </section>
                                      {
                                        loadingImage === true?
                                              <div style={{marginTop:'5%',alignItems: 'center',justifyContent: 'center',width:'20%',margin:'auto',}}>
                                                  <p  style={{textAlign:'center'}} ><CircularProgress  /></p>
                                              </div>
                                            :
                                          <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2 }}>
                                              <Button fullWidth variant='contained' type='reset' color='error' onClick={handleClose} endIcon={<MdOutlineCancel />}>Cancel</Button>
                                              <Button  fullWidth variant="contained"  onClick={uploadFileAws} endIcon={<MdProductionQuantityLimits />}>Upload</Button>
                                          </DialogActions>
                                      }
                              </DialogContent>
                              :
                              <DialogContent sx={{ width: { xs: 230, md: 650, xl: 350 },  justifyContent: 'center' }}>
                                
                                  <div className='image-and-select'>
                                       <div className="select">
                                        <select className="format" id="format"  onChange={handleType}>
                                            <option selected disabled> {typeName}</option>
                                            {projects.map(item =>                                           
                                                    <option label={item.name} key={item.name} value={item._id}>{item.name}</option>
                                            )}
                                          
                                        </select>
                                      </div>
                                      <button className="buttom-make collectionDetail-but-image " style={{marginTop:'6%'}} onClick={()=>openImageData(!openImage)}>
                                      <BsImage  style={{ fontSize: 25, }}/>
                                      </button>
                                  </div>
                                 
                                  <h4>Edit Collections</h4>
                                    
                               
                                    <br/>
                                        <div style={{width:'97%',position:'relative',right:'2.5%'}}>
                                              
                                              <TextField label="Name" name='name'    value={productInfo.name} style={{marginBottom:'3%'}}  onChange={handleOnchange} variant="outlined" fullWidth />
                                              
                                              <TextField label="Description" name='Description'    value={productInfo.Description}  onChange={handleOnchange}  variant="outlined" fullWidth />
                                              
                                              <Grid item xs={12} sx={{ margin: "10px auto" }}>
                                            
                                              <MarkdownEditor
                                              light="Light" 
                                              value={markdownVal}
                                              onChange={(value) => {
                                                setMarkdownVal(value);
                                              }}
                                            />
                                            
                                              </Grid>
                                        </div>
                                        {
                                          loadingData === true?
                                              <div style={{marginTop:'10%',alignItems: 'center',justifyContent: 'center',width:'20%',margin:'auto',}}>
                                                    <p  style={{textAlign:'center'}} >  <CircularProgress  /> </p>
                                              </div>
                                            :
                                          <DialogActions sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', mt: 2,paddingRight:'9%' }}>
                                            <Button fullWidth variant='contained' type='reset' color='error' onClick={handleClose} endIcon={<MdOutlineCancel />}>Cancel</Button>
                                            <Button type="submit" fullWidth variant="contained" endIcon={<MdProductionQuantityLimits />}>Update</Button>
                                          </DialogActions>
                                        }
                                  </DialogContent>

                               }
                            
                            </form>
                          }
                          
                      </Dialog>
                    </div>
                : step.page  === 2?
                   <div>
                   
                  </div>
               :
             null
            }
            <Dialog open={login}
            keepMounted
            onClose={handleClose}
            TransitionComponent={Transition}

            aria-describedby="alert-dialog-slide-description">
            <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 }, display: 'flex', justifyContent: 'center' }}>
                <Typography variant='h5'> Please Login To Proceed</Typography>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Button variant='contained' onClick={handleToLogin} endIcon={<AiOutlineLogin />} color='primary'>Login</Button>
                <Button variant='contained' color='error' endIcon={<AiFillCloseCircle />} onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
          
          </>
    )
}

export default CollectionDetail