import React, { useEffect ,useState} from 'react'
import axios from 'axios'
import { Container, Typography ,Grid,CircularProgress, Select} from '@mui/material'
import { AiOutlineHeart, AiOutlineShoppingCart, AiFillCloseCircle,AiOutlineClose } from 'react-icons/ai'
import { BsSearch} from 'react-icons/bs'
import {MdOutlineAdd,MdDeleteOutline } from 'react-icons/md'
import { Link, useParams ,useNavigate} from 'react-router-dom'
import { Box } from '@mui/system'
import { AiOutlineSearch ,AiOutlineLogin} from 'react-icons/ai';
import { Button, Dialog, DialogActions, DialogContent, ButtonGroup,TextField,InputAdornment,IconButton, InputLabel, MenuItem, FormControl} from '@mui/material';
import { useContext } from 'react'
import { ContextFunction } from '../../Context/Context'
import CategoryCard from '../../Components/Category_Card/CategoryCard';
import BannerData from '../../Helpers/HomePageBanner';
import Carousel from '../../Components/Carousel/Carousel'
import SearchBar from '../../Components/SearchBar/SearchBar'
import CopyRight from '../../Components/CopyRight/CopyRight'
import Slider from "react-slick";
import {BiDownArrow ,BiSolidDownArrow} from 'react-icons/bi'
import { AiFillDelete} from 'react-icons/ai'
import { getCart, getWishList, handleLogOut, handleClickOpen, Transition } from '../../Constants/Constant'
import ProductCard from './ProductCollectionCard'
import CollectionCard from '../../function/CollectionCard'
import { toast } from 'react-toastify';
import './collectionList.css'
// import "~slick-carousel/slick/slick.css"; 
// import "~slick-carousel/slick/slick-theme.css";
const  CollectionList = (props) => {
    const { setCart } = useContext(ContextFunction)
    const [productData, setProductData] = useState([])
    const [productOld, setProductOld] = useState([])
    const [sectionsData, setSectionsData] = useState([])
    const [loading,setLoading] = useState(false);
    const [addProduct,setAddProduct]= useState([]);
    const [quantity,setQuantity]= useState([]);
    const [openModal,setOpenModal] = useState(false);
    const [section,setSection] = useState(null);
    const [loadingData,setLoadingData] = useState(false);
    const [holdData,setholdData]= useState(null);
    const [quantitys,setQuantitys]= useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [productQuantity, setProductQuantity] = useState(1)
    const [openAlert, setOpenAlert] = useState(false);
    const [closeSearch,setCloseSearch]= useState(false);
  
    let authToken = localStorage.getItem('Authorization')
    let setProceed = authToken ? true : false
    let navigate = useNavigate()
    useEffect(() => {
      setLoading(true)
      getAllProduct()
      getUserCollection()
        // getCart()
        window.scroll(0, 0)
    }, [])

    const getAllProduct = async () => {
      // console.log('process.env.REACT_APP_GET_CART',process.env.REACT_APP_GET_CART)
      if (setProceed) {
          const { data } = await axios.get(`${process.env.REACT_APP_FETCH_PRODUCT}`,

              {
                  headers: {
                      'Authorization': authToken
                  }
              })
        // console.log('-----------------4---------data',data)
        setLoading(false)
        setProductData(data);
        setProductOld(data)
      }else{
        const { data } = await axios.get(`${process.env.REACT_APP_FETCH_PRODUCT}`,

              {
                  headers: {
                      'Authorization': authToken
                  }
              })
        // console.log('---------------5-------data',data)
        setLoading(false)
        setProductData(data);
        setProductOld(data)
      }
   
  }
  

  const getUserCollection = async () => {
    // console.log('process.env.REACT_APP_GET_CART',process.env.REACT_APP_GET_CART)
        if (setProceed) {
          const { data } = await axios.get(`${process.env.REACT_APP_SECTIONS}/user`,

                {
                    headers: {
                        'Authorization': authToken
                    }
                })
          // console.log('-----------------getUserCollection---------data',authToken,setProceed,data.data)
             setLoading(false)
              if(data.data.length === 0){
                
              }else{
                // arr1.push(...arr2);
                // console.log('-----------------formattedData---------data',)
                // console.log('-----------------formattedData-----2----data',data.data[0].products, data.data[0].quantity)
                setSection(data.data[0])
                setQuantitys(data.data[0].quantity)
                setAddProduct(formattedData(data.data[0].products, data.data[0].quantity))
              }
              setSectionsData(data.data);
        
        }else{
          setOpenAlert(true)      
       }
  }


  const handleToLogin = () => {
    navigate('/login')
};
    

  const formattedData = (data, array) => data.map((obj, i) => (  
    {
      ...obj,
      quantity: array[i]
    }
  ));


    const  SampleNextArrow =(props) =>{
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style, display: "none", background: "red" }}
          onClick={onClick}
        />
      );
    }

    const  SamplePrevArrow =(props)=> {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style, display: "none", background: "green" }}
          onClick={onClick}
        />
      );
    }

    const sendData = async(dataSender) =>{
      //  console.log('login -------------->',dataSender)
      //  dataSender.quantity = 
       setholdData(dataSender)
       setOpenModal(true)
       setProductQuantity(1)
     
    }
   
   
    // setQuantity

    const pickSelection =(data)=>{
      setSection(data)
      // console.log('-------data.products------data',data)
      // setAddProduct(data.products)
    
      setSection(data)
      setQuantitys(data.quantity)
      setAddProduct(formattedData(data.products, data.quantity))
    }

    const handleClose = () => {
      setOpenModal(false)
  };


  const handleSubmit = (data) =>{

  }

  const submitQuantity = async()=>{

      try {
        let ids = addProduct.find(x => x._id === holdData._id)
        
           if(ids === null || ids === undefined){
            holdData.quantity = productQuantity
            setAddProduct([...addProduct, holdData])
                const { data } = await axios.post(`${process.env.REACT_APP_SECTIONS}/sections/add/${section._id}`,
                {
                  product:holdData._id,
                  quantity: productQuantity ,
                  section:section._id,
                },{
                    headers: {
                        'Authorization': authToken
                    }
                })
              
                // console.log('------------res-----4---------data',data)
                if (data.status === true) {
                
                  setSectionsData(data.data)
                  setProductQuantity(1)
                  setOpenModal(false)
                  toast.success("added", { autoClose: 500, theme: 'colored' })
                 
                   } else {
                    toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
                  }

            }else{
              if (ids._id===holdData._id) {
                toast.error("Sorry this quiz is already added", { autoClose: 500, theme: 'colored' })
               } else {
                //  console.log('-------not yet-----data',ids,holdData._id)
                 setAddProduct([...addProduct, holdData])
                  const { data } = await axios.post(`${process.env.REACT_APP_SECTIONS}/sections/add/${section._id}`,
                  {
                    product:holdData._id,
                    section:section._id,
                   },{
                      headers: {
                          'Authorization': authToken
                      }
                  })
                
                  // console.log('--------------res---4---------data',data)
                  if (data.status === true) {
                    setProductQuantity(1)
                    setSectionsData(data.data)
                    setOpenModal(false)
                    toast.success("added", { autoClose: 500, theme: 'colored' })
                     
                  }
                else {
                    toast.error("Some thing went wrong", { autoClose: 500, theme: 'colored' })
                }
            }
          }
         } catch (error) {
            toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
      }
      // setAddProduct([...addProduct, data])
      
      // setSectionsData([...sectionsData., data])
      //  sendToCollection(data)
  }
 
  const increaseQuantity = () => {
    setProductQuantity((prev) => prev + 1)
    if (productQuantity >= 30) {
        setProductQuantity(1)
    }
}
const decreaseQuantity = () => {
    setProductQuantity((prev) => prev - 1)
    if (productQuantity <= 1) {
        setProductQuantity(1)
    }
}


  const  SearchData  = (data) =>{
    // console.log("data ----true-judge-school----->",data)
    
      
  }

const handleSearch = async (event) => {
  // console.log("data ----false--article----3->",event.target.value)
  try {
  // setSearchTerm(event.target.value);
  // const newFilteredData = data.filter(item =>
  //     (item.name && item.name.includes(event.target.value)) ||
  //     (item.type && item.type.includes(event.target.value)) ||
  //     (item.brand && item.brand.includes(event.target.value)) ||
  //     (item.category && item.category.includes(event.target.value)) ||
  //     (item.author && item.author.includes(event.target.value)) ||
  //     (item.description && item.description.includes(event.target.value)) ||
  //     (item.gender && item.gender.includes(event.target.value))
  // );
  // setFilteredData(newFilteredData);
  setSearchTerm(event.target.value);
  setCloseSearch(true)
     if(event.target.value.length >= 2){
      
      // console.log('--------------new enter--------data',)
            const { data } = await axios.get(`${process.env.REACT_APP_PRODUCT}/index?search=${event.target.value}`,
           {
                headers: {
                    'Authorization': authToken
                }
            })

            // console.log('--------------res---4---------',data            )
            // console.log('--------------res---4---------',data            )
            if (data.error === false) {
              if(data.data === undefined){

              }else{
                setProductData(data.data)
              }
             
              // toast.success("added", { autoClose: 500, theme: 'colored' })
              
            }

          }

          } catch (error) {
            toast.error(error.response.data.error, { autoClose: 500, theme: 'colored' })
        }
};

const handleClickShowPassword = () =>{
  // console.log("data ----false--article----3->",)
  setCloseSearch(!closeSearch)
  setProductData(productOld)
  setSearchTerm('')
}

function ToSeoUrl(url) {
        
  // make the url lowercase         
  var encodedUrl = url.toString().toLowerCase(); 

  // replace & with and           
  encodedUrl = encodedUrl.split(/\&+/).join("-and-")

  // remove invalid characters 
  encodedUrl = encodedUrl.split(/[^a-z0-9]/).join("-");       

  // remove duplicates 
  encodedUrl = encodedUrl.split(/-+/).join("-");

  // trim leading & trailing characters 
  encodedUrl = encodedUrl.trim('-'); 

  return encodedUrl; 
}


    // const removeBook =sectionBooks.filter(item => item._id !== bookStrodInSection.data._id)

    return (
             <>
               <CollectionCard   sections={sectionsData} section={section}  products={addProduct} quantity={quantity} pickSelection={pickSelection} />

                      <div className="collectionList-searchBody">
                          <TextField
                              id="search"
                              size='small'
                              label="Search Products"
              
                              value={searchTerm}
                              onChange={handleSearch}
                              className={'collectionList-search'}
                              
                          />
                          {closeSearch ?   <a onClick={handleClickShowPassword}><AiOutlineClose  style={{ fontSize: 25 , color:'red',marginTop:'2%',marginLeft:'2%'}}  /> </a>: null}
                     </div>
                      <div className="collectionList-product">
                      {
                        loading === true?
                          <div style={{paddingTop:'7%',alignItems: 'center',justifyContent: 'center',width:'25%',margin:'auto'}}>
                          <p  style={{textAlign:'center'}} ><CircularProgress /></p>
                          </div>
                          :
                        <div>
                         {
                           productData.length === 0 ? 
                           <Typography variant="h6" textAlign="center" margin="25px 0">Product Not Found</Typography>
                          :
                          <Container maxWidth='xl' style={{ marginTop: 10, display: "flex", justifyContent: 'center', flexWrap: "wrap", paddingBottom: 20, marginBottom: 30, width: '100%' }}>
                              {productData.map(prod => (
                                
                                     <ProductCard prod={prod} to={`/Detail/type/category/${ToSeoUrl(prod.name)}/${prod._id}`} key={prod._id}  sendData={sendData}/>
                              ))}
                          </Container>
                         }
                            
                        </div>
                      }
                      </div>

                      <Dialog
                        open={openModal}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleClose}
                        aria-describedby="alert-dialog-slide-description"
                      >
                      <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 }, display: 'flex', justifyContent: 'center' }}>
                        <form onSubmit={handleSubmit}>
                          <DialogContent sx={{   justifyContent: 'center' }}>
                          {
                            loadingData === true?
                            <div className='collectionslist-delete-btn'>
                                    <h4>Do you want to delete this data</h4>
                                <div style={{marginTop:'10%',alignItems: 'center',justifyContent: 'center',width:'20%',margin:'auto',}}>
                                    <p  style={{textAlign:'center'}} >  <CircularProgress  /> </p>
                                </div>
                            </div>
                              :
                                <div className='collectionslist-delete-btn'>
                                    <h4>Add Product Quantity</h4>
                                     <br/>
                                    <ButtonGroup variant="outlined" aria-label="outlined button group">
                                        <Button onClick={increaseQuantity}>+</Button>
                                        <Button>{productQuantity}</Button>
                                        <Button onClick={decreaseQuantity}>-</Button>
                                    </ButtonGroup>
                                     <br/>
                                     <div style={{display:'flex'}}>
                                        <Button variant='contained' endIcon={ <MdOutlineAdd style={{ fontSize: 29 , color:'#ffffff'}}/> } color='primary' style={{marginTop:'3%',marginRight:'2%'}} onClick={()=>submitQuantity()}>Add</Button>
                                        <Button variant='contained'  color='error'   style={{marginRight:'1%',marginTop:'3%'}} onClick={() => handleClose(false)} endIcon={<AiFillCloseCircle />}>Close</Button>
                                     </div>
                     
                                </div>
                          }
                            </DialogContent>
                        </form>
                        </DialogContent>
                  </Dialog>
                  <Dialog open={openAlert}
                  keepMounted
                  onClose={handleClose}
                  TransitionComponent={Transition}
  
                  aria-describedby="alert-dialog-slide-description">
                  <DialogContent sx={{ width: { xs: 280, md: 350, xl: 400 }, display: 'flex', justifyContent: 'center' }}>
                      <Typography variant='h5'> Please Login To Proceed</Typography>
                  </DialogContent>
                  <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                      <Button variant='contained' onClick={handleToLogin} endIcon={<AiOutlineLogin />} color='primary'>Login</Button>
                      <Button variant='contained' color='error' endIcon={<AiFillCloseCircle />} onClick={handleClose}>Close</Button>
                  </DialogActions>
              </Dialog>

                  
            <CopyRight sx={{ mt: 8, mb: 10 }} />
        </ >
    )
}

export default CollectionList